.cart-tabs .ant-tabs-nav-list {
  margin: 0 auto;
  gap: 20px;
}

.cart-tabs .ant-tabs-tab {
  min-width: 150px;
  display: flex;
  justify-content: center;
}

@media (max-width: 575px) {
  .cart-tabs .ant-tabs-tab {
    min-width: 40%;
  }
}
